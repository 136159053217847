import React, { useEffect, useState } from "react";
import {
  Table,
  Container,
  Row,
  Col,
  Modal,
  Button,
  Card,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AddClient from "./Forms/AddClient";

const Clients = () => {
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState("Add");
  const [data, setData] = useState([]);
  const [item, setItem] = useState({});
  const navigate = useNavigate();
  // Function to handle opening the modal for adding a new Client
  const handleOpen = () => {
    // setType('Add');
    // setVisible(true);
    navigate("/master/clients/add");
  };

  // Function to handle opening the modal for editing a client
  const handleEdit = (item) => {
    setType("Edit");
    setItem(item);
    setVisible(true);
  };

  // Function to handle deleting a client
  const handleDelete = (id) => {
    fetch(`/api/client/delete/${id}`, { method: "DELETE" }).then(() => fetchData());
  };

  const onClose = () => {
    setType("Add");
    setVisible(false);
  };

  // Fetch Client data from the backend
  const fetchData = async () => {
    try {
      const response = await fetch("/api/clients");
      const dataReceive = await response.json();
      console.log(dataReceive);
      setData(dataReceive);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const columns = [
    "Id",
    "Type",
    "Code",
    "Name",
    "Email",
    "Mobile",
    "Address",
    "Image",
  ]; // Modify columns according to your clients table

  const finalData = Array.isArray(data)
    ? data.map((item, index) => {
        return {
          Id: item.id,
          Type: item.client_type,
          Code: item.client_code,
          Name: item.client_name,
          Email: item.client_email,
          Mobile: item.client_mobile,
          Address: item.client_address,
          Image: item.client_image,
          Action: (
            <div style={{ display: "flex", gap: "1rem" }}>
              <Button variant="primary" onClick={() => handleEdit(item)}>
                Edit
              </Button>
              <Button variant="danger" onClick={() => handleDelete(item.id)}>
                Delete
              </Button>
            </div>
          ),
        };
      })
    : [];
  console.log(finalData);
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container className="p-4">
      <Card
        className="mb-4 bg-light"
        style={{ boxShadow: "4px 4px 10px black " }}
      >
        <Card.Header className="bg-primary text-light">
          <h5 className="mb-0">CLIENT</h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Button
                style={{ backgroundColor: "#00bcd4", color: "white" }}
                onClick={handleOpen}
                className="mb-3 bg-success"
                size="vsm">
                Add Client
              </Button>
            </Col>
          </Row>

          <Table striped bordered hover responsive variant="light">
            <thead style={{ backgroundColor: "black", color: "white" }}>
              <tr>
                {columns.map((col, index) => (
                  <th
                    key={index}
                    className="text-center bg-black  h6 font-weight-bold py-1"
                    style={{
                      backgroundColor: "#00bcd4",
                      color: "white",
                      width:
                        index === 0 ? "50px" : index === 1 ? "100px" : "150px",
                    }} // Adjust widths as needed
                  >
                    {col}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "#17a1b0", color: "black" }}>
              {finalData.length > 0 ? (
                finalData.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#cc7a00" : "#ffcc80",
                    }}
                  >
                    <td>
                      <b>{item.id}</b>
                    </td>
                    <td>{item.client_type}</td>
                    <td>{item.client_code}</td>
                    <td>{item.client_name}</td>
                    <td>{item.client_email}</td>
                    <td>{item.client_mobile}</td>
                    <td>{item.client_address}</td>
                    <td>{item.client_image}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length} className="text-center">
                    No Client Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={visible} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {type === "Add" ? "Add Client" : "Edit Client"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {type === "Add" ? (
            <p>Form for Adding Client</p> // Replace with actual form component
          ) : (
            <p>Form for Editing Client</p> // Replace with actual form component
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Clients;
