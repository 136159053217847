import React, { useState, useEffect } from 'react';
import { Button, Form, Accordion, Table } from 'react-bootstrap';

const AddVisit = () => {

  const [formData, setFormData] = useState({
    date: '',
    time: '',
    visitedTo: '',
    couponCollectionList: [],
    couponSettlementList: []
  });

  const [doctors, setDoctors] = useState([]);
  const [products, setProducts] = useState([]); // New state for products

  const [couponCollection, setCouponCollection] = useState({
    product_name: '',
    qty: '',
    points: 0,
    bonous: 0
  });

  const [couponSettlement,setCouponSettlement] = useState({
    product_name:'',
    qty:'',
    points:0
    // bonous:0
  })

  const [total, setTotal] = useState({
    totalQty: 0,
    totalPoints: 0,
    totalBonus: 0
  });

  const [totalSettlement, setTotalSettlement] = useState({
    totalQty1: 0,
    totalPoints1: 0,
    // totalBonus1: 0
  });

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const response = await fetch("/api/doctor");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setDoctors(data);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await fetch("/api/products");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProducts(data); // Set the fetched products data
      } catch (err) {
        console.error("Error fetching products:", err);
      }
    };

    fetchDoctors();
    fetchProducts(); // Fetch products on component mount
  }, []);

  const handleFormChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCouponCollectionChange = (e) => {
    const { name, value } = e.target;

    if (name === 'productName') {
      // Fetch bonus and special bonus points when the product is selected
      const selectedProduct = products.find(product => product.id == value);
      if (selectedProduct) {
        setCouponCollection(prevCoupon => ({
          ...prevCoupon,
          product_name: selectedProduct.product_name, // Keep product ID as `productName`
          points: selectedProduct.points,
          bonous: selectedProduct.bonous
        }));
      }
    } else {
      setCouponCollection({
        ...couponCollection,
        [name]: value
      });
    }
  };

  const handleCouponSettlmentChange = (e) => {
    const { name, value } = e.target;

    if (name === 'productName') {
      // Fetch bonus and special bonus points when the product is selected
      const selectedProduct = products.find(product => product.id == value);
      if (selectedProduct) {
        setCouponSettlement(prevCoupon => ({
          ...prevCoupon,
          product_name: selectedProduct.product_name, // Keep product ID as `productName`
          points: selectedProduct.points_on_settlement,
          // bonous: selectedProduct.bonous
        }));
      }
    } else {
      setCouponSettlement({
        ...couponSettlement,
        [name]: value
      });
    }
  };

  const handleQtyChange = (e) => {
    const qty = e.target.value;
    setCouponCollection(prevCoupon => ({
      ...prevCoupon,
      // product_name:couponCollection.product_name,
      qty: qty,
      points: qty * couponCollection.points, // Multiply the quantity by the bonus point
      bonous: qty * couponCollection.bonous 
    }));
    // setCouponCollection({
    //   ...couponCollection,
    //   qty: qty,
    //   bonusQty: qty * couponCollection.bonusQty, // Multiply the quantity by the bonus point
    //   specialBonusQty: qty * couponCollection.specialBonusQty // Multiply the quantity by the special bonus point
    // });
    setCouponSettlement(prevCoupon => ({
      ...prevCoupon,
      // product_name:couponCollection.product_name,
      qty: qty,
      points: qty * couponSettlement.points, // Multiply the quantity by the bonus point
      bonous: qty * couponSettlement.bonous 
    }));
  };

  const handleAddCouponCollection = () => {
    setFormData({
      ...formData,
      couponCollectionList: [...formData.couponCollectionList, couponCollection],
    });

    // Update the total sums
    setTotal({
      totalQty: total.totalQty + parseInt(couponCollection.qty),
      totalPoints: total.totalPoints + parseInt(couponCollection.points),
      totalBonus: total.totalBonus + parseInt(couponCollection.bonous)
    });

    setCouponCollection({ product_name: '', qty: '', points: 0, bonous: 0 });
  };

  const handleAddCouponSettlement = () => {
    setFormData({
      ...formData,
      couponSettlementList: [...formData.couponSettlementList, couponSettlement],
    });

    // Update the total sums
    setTotalSettlement({
      totalQty1: totalSettlement.totalQty1 + parseInt(couponSettlement.qty),
      totalPoints1: totalSettlement.totalPoints1 + parseInt(couponSettlement.points),
      // totalBonus1: totalSettlement.totalBonus1 + parseInt(couponSettlement.bonous)
    });

    setCouponSettlement({ product_name: '', qty: '', points: 0 });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const dropdown = document.querySelector("select[name='productName']");
    // if (dropdown && dropdown.value === "") {
    //     // You can set a default product value programmatically
    //     dropdown.value = "1"; // Assuming "1" is a valid product ID or value
    // }

    // Find the selected doctor to get the full name
    const selectedDoctor = doctors.find(doctor => doctor.doctor_id == formData.visitedTo);

    // Construct the payload for visit details
    const visitHeadPayload = {
        date: formData.date,
        time: formData.time,
        visitedTo: formData.visitedTo,
        visitedToFullName: selectedDoctor ? selectedDoctor.fullname : "",  // Send full name of doctor
        totalQty: total.totalQty,
        totalPoints: total.totalPoints,
        totalBonus: total.totalBonus,
        totalQty1: totalSettlement.totalQty1,
        totalPoints1: totalSettlement.totalPoints1,
        
    };

    try {
        // Send visit head data to the backend
        const visitHeadResponse = await fetch('/api/addvisithead', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(visitHeadPayload), // Send the payload as JSON
        });

        if (!visitHeadResponse.ok) {
            throw new Error('Failed to save visit head details');
        }

        // Now send the coupon collection details
        const couponPromises = formData.couponCollectionList.map(async (coupon) => {
            // Find the product details by matching the product name
            const selectedProduct = products.find(product => product.product_name === coupon.product_name);

            // Construct the payload for each coupon collection item
            const couponPayload = {
                product_name: selectedProduct ? selectedProduct.product_name : coupon.product_name,  // Get product name
                product_id: selectedProduct ? selectedProduct.id : null,  // Get product id
                qty: coupon.qty,
                points: coupon.points,
                bonus: coupon.bonous,
                transaction_group:'coupon_collected'
            };

            // Send the coupon collection data to the backend
            const couponResponse = await fetch('/api/addvisitdetails', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(couponPayload), // Send the coupon payload as JSON
            });

            if (!couponResponse.ok) {
                throw new Error('Failed to save coupon collection details');
            }
        });

        // Wait for all coupon collection details to be sent
        await Promise.all(couponPromises);

        const settlementPromises = formData.couponSettlementList.map(async (coupon) => {
          // Find the product details by matching the product name
          const selectedProduct = products.find(product => product.product_name === coupon.product_name);

          // Construct the payload for each coupon collection item
          const settlementPayload = {
              product_name: selectedProduct ? selectedProduct.product_name : coupon.product_name,  // Get product name
              product_id: selectedProduct ? selectedProduct.id : null,  // Get product id
              qty: coupon.qty,
              points: coupon.points,
              // bonus: coupon.bonous,
              transaction_group:'coupon_settlement'
          };

          // Send the coupon collection data to the backend
          const settlementResponse = await fetch('/api/addvisitdetails', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(settlementPayload), // Send the coupon payload as JSON
          });

          if (!settlementResponse.ok) {
              throw new Error('Failed to save coupon collection details');
          }
      });

        // If all requests are successful, show success message
        alert('Visit and coupon details saved successfully');
        // Optionally reset the form or perform additional actions
    } catch (error) {
        console.error('Error:', error);
        alert('Failed to save the visit details. Please try again.');
    }
};


  return (
    <div className="container my-4">
      <h3>Add Visit</h3>
      <Form >
        <Form.Group className="mb-3">
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="date"
            name="date"
            value={formData.date}
            onChange={handleFormChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Time</Form.Label>
          <Form.Control
            type="time"
            name="time"
            value={formData.time}
            onChange={handleFormChange}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Visited To</Form.Label>
          <Form.Control
            as="select"
            name="visitedTo"
            value={formData.visitedTo}
            onChange={handleFormChange}
            required
          >
            <option value="">Select a Doctor</option>
            {doctors.map((doctor) => (
              <option key={doctor.doctor_id} value={doctor.doctor_id}>
                {doctor.fullname}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Accordion defaultActiveKey="0" className="mb-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="bg-primary text-white">
              Coupon Collection
            </Accordion.Header>
            <Accordion.Body>
              <Form.Group className="mb-3">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  as="select"
                  name="productName"
                  value={couponCollection.product_name}
                  onChange={handleCouponCollectionChange}
                  required
                >
                  <option value="">Select Product</option>
                  {products.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.product_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Qty</Form.Label>
                <Form.Control
                  type="text"
                  name="qty"
                  value={couponCollection.qty}
                  onChange={handleQtyChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Points</Form.Label>
                <Form.Control
                  type="text"
                  name="bonusQty"
                  value={couponCollection.points}
                  readOnly
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Bonus Points</Form.Label>
                <Form.Control
                  type="text"
                  name="specialBonusQty"
                  value={couponCollection.bonous}
                  readOnly
                />
              </Form.Group>

              <Button variant="success" onClick={handleAddCouponCollection}>
                Add List
              </Button>

              {formData.couponCollectionList.length > 0 && (
                <Table striped bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Qty</th>
                      <th>Points</th>
                      <th>Bonus Pt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData.couponCollectionList.map((item, index) => (
                      <tr key={index}>
                        <td>{products.find(p => p.product_name == item.product_name)?.product_name || ''}</td>
                        <td>{item.qty}</td>
                        <td>{item.points}</td>
                        <td>{item.bonous}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Accordion.Body>

            <div style={{ backgroundColor: '#f8f9fa', padding: '10px', marginTop: '10px' }}>
              <div className="row">
                <div className="col"><strong>Total</strong></div>
                <div className="col"><strong>Qty: {total.totalQty}</strong></div>
                <div className="col"><strong>Points: {total.totalPoints}</strong></div>
                <div className="col"><strong>Bonus: {total.totalBonus}</strong></div>
              </div>
            </div>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header className="bg-primary text-white">
              Coupon Settlement
            </Accordion.Header>
            <Accordion.Body>
              <Form.Group className="mb-3">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  as="select"
                  name="productName"
                  value={couponSettlement.product_name}
                  onChange={handleCouponSettlmentChange}
                  required
                >
                  <option value="">Select Product</option>
                  {products.map((product) => (
                    <option key={product.id} value={product.id}>
                      {product.product_name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Qty</Form.Label>
                <Form.Control
                  type="text"
                  name="qty"
                  value={couponSettlement.qty}
                  onChange={handleQtyChange}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Points</Form.Label>
                <Form.Control
                  type="text"
                  name="bonusQty"
                  value={couponSettlement.points}
                  readOnly
                />
              </Form.Group>

              {/* <Form.Group className="mb-3">
                <Form.Label>Bonus Points</Form.Label>
                <Form.Control
                  type="text"
                  name="specialBonusQty"
                  value={couponSettlement.bonous}
                  readOnly
                />
              </Form.Group> */}

              <Button variant="success" onClick={handleAddCouponSettlement}>
                Add List
              </Button>

              {formData.couponSettlementList.length > 0 && (
                <Table striped bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th>Qty</th>
                      <th>Points</th>
                      {/* <th>Bonus Pt</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {formData.couponSettlementList.map((item, index) => (
                      <tr key={index}>
                        <td>{products.find(p => p.product_name == item.product_name)?.product_name || ''}</td>
                        <td>{item.qty}</td>
                        <td>{item.points}</td>
                        {/* <td>{item.bonous}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Accordion.Body>

            <div style={{ backgroundColor: '#f8f9fa', padding: '10px', marginTop: '10px' }}>
              <div className="row">
                <div className="col"><strong>Total</strong></div>
                <div className="col"><strong>Qty: {totalSettlement.totalQty1}</strong></div>
                <div className="col"><strong>Points: {totalSettlement.totalPoints1}</strong></div>
                {/* <div className="col"><strong>Bonus: {totalSettlement.totalBonus1}</strong></div> */}
              </div>
            </div>
          </Accordion.Item>
        </Accordion>

        <Button variant="primary" onClick={handleSubmit}>
          Save Visit
        </Button>
        <Button variant="secondary" className="ms-2">
          Cancel
        </Button>
      </Form>
    </div>
  );
};

export default AddVisit;
