import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const OpeningStock = () => {
  const [staffs, setStaffs] = useState([]);
  const [formData, setFormData] = useState({
    stock_point_holder: 0,
    product_name: '',
    quantity: '', // This will hold the input value from the form
  });

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productList, setProductList] = useState([]);

  // Fetch staff data
  useEffect(() => {
    fetch('/api/staffs')
      .then((response) => response.json())
      .then((data) => {
        setStaffs(data);
        if (data.length > 0) {
          setFormData((prevData) => ({
            ...prevData,
            stock_point_holder: data[0].id,
          }));
        }
      })
      .catch((error) => console.error('Error fetching staff:', error));
  }, []);

  // Fetch product data from API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('/api/products');
        const data = await response.json();
        setProductList(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Fetch the quantity from outwardchallan_details API based on the selected product
  const fetchOutwardProductQuantity = async (productId) => {
    try {
      const response = await fetch(`/api/outwardchallan_details/${productId}`);
      const data = await response.json();
      return data.quantity; // Return the quantity from outwardchallan_details
    } catch (error) {
      console.error('Error fetching product quantity from outwardchallan_details:', error);
      return 0;
    }
  };

  // Handle adding product to the stock list and sending to the server
  const handleAddToStock = async () => {
    if (formData.product_name && formData.quantity && formData.stock_point_holder) {
      // Find the selected product
      const selectedProduct = productList.find(product => product.product_name === formData.product_name);

      // Find the staff member based on stock_point_holder (id)
      const selectedStaff = staffs.find(staff => staff.id == formData.stock_point_holder);

      // Fetch the quantity from outwardchallan_details
      const outwardProductQuantity = await fetchOutwardProductQuantity(selectedProduct.id);

      // Prepare the data to send
      const dataToSend = {
        product_name: selectedProduct ? selectedProduct.product_name : '', // Product name from products API
        product_id:selectedProduct ? selectedProduct.id:'',
        stock_in_qty: outwardProductQuantity, // Quantity from outwardchallan_details API
        quantity: formData.quantity, // User entered quantity
        stock_point_holder: formData.stock_point_holder, // Staff id
        staff_code: selectedStaff ? selectedStaff.code : '', // Staff code
      };

      // Send the data to the API
      fetch('/api/stock', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Product added successfully:', data);

          // Add the new product to the selected products list
          setSelectedProducts([
            ...selectedProducts,
            {
              product_name: selectedProduct.product_name, // Display product name
              stock_in_qty: outwardProductQuantity, // Display outward stock quantity
              quantity: formData.quantity, // Display input quantity
            },
          ]);

          // Clear the form fields
          setFormData({
            ...formData,
            product_name: '',
            quantity: '',
          });
        })
        .catch((error) => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
  };

  return (
    <div className="container mt-5">
      <h2>Opening Stock Form</h2>
      <form>
        <div className="mb-3">
          <label htmlFor="stock_point_holder" className="form-label">Stock Point/Holder</label>
          <select
            className="form-select"
            id="stock_point_holder"
            name="stock_point_holder"
            value={formData.stock_point_holder}
            onChange={handleInputChange}
            required
          >
            {staffs.map((staff) => (
              <option key={staff.id} value={staff.id}>
                {staff.fullname} ({staff.designation})
              </option>
            ))}
          </select>
        </div>

        <div className="row mb-3">
          <div className="col">
            <label htmlFor="product_name" className="form-label">Product Name</label>
            <select
              className="form-select"
              id="product_name"
              name="product_name"
              value={formData.product_name}
              onChange={handleInputChange}
              required
            >
              <option value="">Select a product</option>
              {productList.map((product) => (
                <option key={product.id} value={product.product_name}>
                  {product.product_name}
                </option>
              ))}
            </select>
          </div>

          <div className="col">
            <label htmlFor="quantity" className="form-label">Quantity</label>
            <input
              type="number"
              className="form-control"
              id="quantity"
              name="quantity"
              value={formData.quantity}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="col d-flex align-items-end">
            <button type="button" className="btn btn-primary" onClick={handleAddToStock}>
              Add to Stock
            </button>
          </div>
        </div>
      </form>

      <h3>Product List</h3>
      {selectedProducts.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Product Name</th>              
              <th>Opening Qty</th>
            </tr>
          </thead>
          <tbody>
            {selectedProducts.map((product, index) => (
              <tr key={index}>
                <td>{product.product_name}</td>                
                <td>{product.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No products added yet.</p>
      )}
    </div>
  );
};

export default OpeningStock;
