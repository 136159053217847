// src/components/Sidebar.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Slidebar = ({ role }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [masterOpen, setMasterOpen] = useState(false);
  const [transactionOpen, setTransactionOpen] = useState(false);
  const [reportsOpen, setReportsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleMasterMenu = () => {
    setMasterOpen(!masterOpen);
  };

  const toggleTransactionMenu = () => {
    setTransactionOpen(!transactionOpen);
  };

  const toggleReportsMenu = () => {
    setReportsOpen(!reportsOpen);
  };

  const renderAdminMenu = () => (
    <>
      <li className="nav-item">
        <a
          href="#"
          className="nav-link link-dark"
          style={{backgroundColor:" #a6a6a6"}}
          onClick={() => navigate("/admin_dashboard")}
        >
          <i className="bi bi-speedometer2"></i> {!isCollapsed && "Dashboard"}
        </a>
      </li>
      <li className="nav-item" onClick={toggleMasterMenu}>
        <a href="#" className="nav-link link-dark" style={{backgroundColor:" #a6a6a6"}}>
          <i className="bi bi-puzzle"></i> {!isCollapsed && "Master"}
        </a>
        {!isCollapsed && masterOpen && (
          <ul className="nav flex-column ms-3">
            <li className="nav-item" >
              <a
                href="#"
                className="nav-link link-dark" style={{backgroundColor:" #a6a6a6"}}
                onClick={() => navigate("/master/staff")}
              >
                Staff
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className="nav-link link-dark" style={{backgroundColor:" #a6a6a6"}}
                onClick={() => navigate("/master/products")}
              >
                Products
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className="nav-link link-dark" style={{backgroundColor:" #a6a6a6"}}
                onClick={() => navigate("/master/clients")}
              >
                Clients
              </a>
            </li>
          </ul>
        )}
      </li>
      <li className="nav-item" onClick={toggleTransactionMenu}>
        <a href="#" className="nav-link link-dark" style={{backgroundColor:" #a6a6a6"}}>
          <i className="bi bi-people"></i> {!isCollapsed && "Transaction"}
        </a>
        {!isCollapsed && transactionOpen && (
          <ul className="nav flex-column ms-3">
            <li className="nav-item">
              <a
                href="#"
                className="nav-link link-dark" style={{backgroundColor:" #a6a6a6"}}
                onClick={() => navigate("/transaction/openingstock")}
              >
                Opening Stock
              </a>
            </li>

            <li className="nav-item">
              <a
                href="#"
                className="nav-link link-dark"
                style={{backgroundColor:" #a6a6a6"}}
                onClick={() => navigate("/transaction/stock-return")}
              >
                Stock Return
              </a>
            </li>

            <li className="nav-item">
              <a
                href="#"
                className="nav-link link-dark"
                style={{backgroundColor:" #a6a6a6"}}
                onClick={() => navigate("/transaction/Stock-Issue-List")}
              >
                Stock Issue List
              </a>
            </li>
          </ul>
        )}
      </li>
      <li className="nav-item" onClick={toggleReportsMenu}>
        <a href="#" className="nav-link link-dark" style={{backgroundColor:" #a6a6a6"}}>
          <i className="bi bi-people"></i> {!isCollapsed && "Reports"}
        </a>
        {!isCollapsed && reportsOpen && (
          <ul className="nav flex-column ms-3">
            <li className="nav-item">
              <a href="#" className="nav-link link-dark " style={{backgroundColor:" #a6a6a6"}}
                onClick={() => navigate("/reports/stock")}  >
                Stock Report
              </a>
            </li>

            <li className="nav-item">
              <a
                href="#"
                className="nav-link link-dark"
                style={{backgroundColor:" # a6a6a6"}}
                onClick={() => navigate("/reports/visit")}
              >
                Visit Report
              </a>
            </li>

            <li className="nav-item">
              <a
                href="#"
                className="nav-link link-dark"
                style={{backgroundColor:" # a6a6a6"}}
                onClick={() => navigate("/reports/coupon-collection")}
              >
                Coupon Collection Report
              </a>
            </li>
          </ul>
        )}
      </li>
    </>
  );

  const renderVsoMenu = () => (
    <>
      <li className="nav-item">
        <a
          href="#"
          className="nav-link link-dark"
          onClick={() => navigate("/vso_dashboard")}
        >
          <i className="bi bi-speedometer2"></i> {!isCollapsed && "Dashboard"}
        </a>
      </li>
      <li className="nav-item">
        <a
          href="#"
          className="nav-link link-dark"
          onClick={() => navigate("/visit")}
        >
          <i className="bi bi-people"></i> {!isCollapsed && "Visit"}
        </a>
      </li>
    </>
  );

  // const renderVsoMenu = () => (
  //   <>
  //     <li className="nav-item">
  //       <a href="#" className="nav-link link-dark" onClick={() => navigate('/vso-dashboard')}>
  //         VSO Dashboard
  //       </a>
  //     </li>
  //     <li className="nav-item" onClick={toggleMasterMenu}>
  //       <a href="#" className="nav-link link-dark"><i className="bi bi-puzzle"></i> {!isCollapsed && 'Master'}</a>
  //       {!isCollapsed && masterOpen && (
  //         <ul className="nav flex-column ms-3">
  //           <li className="nav-item"><a href="#" className="nav-link link-dark" onClick={() => navigate('/master/staff')}>Visit</a></li>
  //           {/* <li className="nav-item"><a href="#" className="nav-link link-dark" onClick={() => navigate('/master/products')}>Products</a></li>
  //           <li className="nav-item">
  //                 <a href="#" className="nav-link link-dark" onClick={() => navigate('/master/mbacourse')}>
  //                   Clients
  //                 </a>
  //               </li> */}
  //         </ul>
  //       )}
  //     </li>
  //     {/* Add more VSO-specific links */}
  //   </>
  // );

  const renderSidebarContent = () => {
    switch (role) {
      case "admin":
        return renderAdminMenu();
      case "VSO":
        return renderVsoMenu();
      case "Doctor":
        return <li>Doctor specific menu</li>;
      case "manager":
        return <li>Manager specific menu</li>;
      default:
        return null;
    }
  };

  return (
    <div
      className={`d-flex flex-column flex-shrink-0 p-4 bg-light ${
        isCollapsed ? "collapsed" : ""
      }`}
      style={{
        width: isCollapsed ? "80px" : "250px",
        height: "100vh",
        transition: "width 0.3s",
        boxShadow: "4px 4px 10px black ",
        position: "relative",
      }}
    >
      <a
        href="/"
        className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
      >
      <span className="fs-4">{!isCollapsed && (role === 'admin' ? 'Admin Panel' : `${role} Panel`)}</span>
  </a>
      <button onClick={toggleSidebar} className="btn btn-primary">
        {isCollapsed ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2 3h12a1 1 0 0 1 0 2H2a1 1 0 0 1 0-2zm0 5h12a1 1 0 0 1 0 2H2a1 1 0 0 1 0-2zm0 5h12a1 1 0 0 1 0 2H2a1 1 0 0 1 0-2z"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="currentColor"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 1 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
            />
          </svg>
        )}
      </button>
      <ul className="nav nav-pills flex-column mb-auto">
        {renderSidebarContent()}
      </ul>
    </div>
  );
};

export default Slidebar;
