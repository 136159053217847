import React, { useState } from "react";
import { Form, Button, Alert, Container, Card} from "react-bootstrap";

const AddClient = ({ close, fetchData }) => {
  const [formData, setFormData] = useState({
    client_type: '',
    client_code: '',
    client_name: '',
    client_email: '',
    client_mobile: '',
    client_address: '',
    client_image: '',
  });

  // State to show success message
  const [successMessage, setSuccessMessage] = useState("");

  // client options
  const clientOptions = [
    { id: "doctor", type_name: "Doctor" },
    { id: "vso", type_name: "VSO" },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make API call to submit the product
      const response = await fetch("/api/addclient", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        // Show success message
        setSuccessMessage(data.message);

        // Reset form fields after successful submission
        setFormData({
          client_type: '',
          client_code: '',
          client_name: '',
          client_email: '',
          client_mobile: '',
          client_address: '',
          client_image: '' ,
        });

        // Refresh client list
        fetchData();

        // Optionally close the modal (you can remove this if you don't want to auto-close)
        close();
      } else {
        console.error("Error adding client:", data.error);
      }
    } catch (error) {
      console.error("Error submitting client:", error);
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
    <Card className="custom-card shadow-lg" style={{ width:'600px',
      maxWidth: '100%' }} >

      {/* Display success message */}

      <Form onSubmit={handleSubmit}>
        {/* Client Type */}
        <Form.Group className="mb-2">
          <Form.Label><b>Client Type</b></Form.Label>
          <Form.Select
            name="client_type"
            value={formData.client_type}
            onChange={handleChange}
            required
          >
            <option value="">Select Client Type</option>
            {/* Hardcoded Client types */}
            {clientOptions.map((client) => (
              <option key={client.id} value={client.id}>
                {client.type_name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        {/* Client Code */}
        <Form.Group className="mb-2">
          <Form.Label><b>Client Code</b></Form.Label>
          <Form.Control
            type="text"
            name="client_code"
            value={formData.client_code}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {/*Client name */}
        <Form.Group className="mb-2">
          <Form.Label><b>Client Name</b></Form.Label>
          <Form.Control
            type="text"
            name="client_name"
            value={formData.client_name}
            onChange={handleChange}
            required
          />

          {/*Client Email */}
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label><b>Email</b></Form.Label>
          <Form.Control
            type="email"
            name="client_email"
            value={formData.client_email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {/* Client Mobile */}
        <Form.Group className="mb-2">
          <Form.Label><b>Mobile</b></Form.Label>
          <Form.Control
            type="text"
            name="client_mobile"
            value={formData.client_mobile}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {/* Client Address */}
        <Form.Group className="mb-2">
          <Form.Label><b>Address</b></Form.Label>
          <Form.Control
            type="text"
            name="client_address"
            value={formData.client_address}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {/* Client Image */}
        <Form.Group className="mb-2">
          <Form.Label><b>Image</b></Form.Label>
          <Form.Control
            type="file"
            name="client_image"
            value={formData.client_image}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {/* Submit Button */}
        <Button variant="success" type="submit" size="sm" >
        <b>Add Client</b>  
        </Button>
      </Form>
      {successMessage && (
        <Alert
          variant="success"
          onClose={() => setSuccessMessage("")}
          dismissible
        >
          {successMessage}
        </Alert>
      )}
      </Card>
    </Container>
  );
};

export default AddClient;
